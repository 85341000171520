import {
	Box,
	Button,
	HStack,
	Image,
	Link,
	Stack,
	Text,
	VStack,
} from '@chakra-ui/react';

import React from 'react';

const LogInBanner = () => {
	return (
		<Box
			margin='24px auto'
			maxW='600px'
			px={{ base: '20px', md: '40px' }}
			py={{ base: '20px', md: '0' }}
			h={{ base: '100%', md: '128px' }}
			borderRadius='8px'
			bgImage='https://shop.doctormedica.co/wp-content/uploads/2024/06/banner_bg.webp'
			backgroundRepeat='no-repeat'
			backgroundSize='cover'
			backgroundPosition='center'>
			<HStack
				h='100%'
				justifyContent='space-between'>
				<VStack
					alignItems='flex-start'
					gap='0'>
					<Text
						fontSize={{ base: '14px', md: '24px' }}
						lineHeight={{ base: '14px', md: '36px' }}
						fontWeight='400'>
						Considering a Purchase?
					</Text>
					<Text
						fontSize={{ base: '14px', md: '24px' }}
						lineHeight={{ base: '14px', md: '36px' }}
						fontWeight='400'>
						<span style={{ color: '#D28C49' }}> Sign in</span> to Explore
						Options
					</Text>
				</VStack>
				<Link href='/sign-in'>
					<Button
						variant='solidBlack'
						h='30px'
						minW='100px'
						padding='12px 20px'
						fontWeight='700'
						color='white'
						bg='#120B0C'
						border='0.4px solid #120B0C'
						borderRadius='48px'
						fontSize='12px'
						_hover={{
							color: '#000',
							bg: '#fff',
							border: '1px solid #120B0C',
						}}>
						SIGN IN
					</Button>
				</Link>
			</HStack>
		</Box>
	);
};

export default LogInBanner;
